import { iPlanCategory } from "./interfaces/plans";

const Plans: iPlanCategory[] = [
  {
    title: "Pessoa física",
    plans: [
      {
        title: "Essencial Fit",
        plans: [
          {
            title: "Fit",
            speed: 500,
            speedType: "MB",
            amount: 89.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/cfcf783d-9d99-4508-a90a-ca060bf5ecf2",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Fit + Móvel",
            speed: 500,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/40b62a6a-ff52-44e2-b360-c9fb600c6de8",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "15 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Fit + Max",
            speed: 500,
            speedType: "MB",
            amount: 119.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7769125e-8f93-42dc-b04a-54e4b9481833",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Smart",
        plans: [
          {
            title: "Smart",
            speed: 700,
            speedType: "MB",
            amount: 99.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/68e87faf-4478-48eb-949a-0d18b8227b6b",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Smart + Móvel",
            speed: 700,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7a5d0b58-fdee-4857-b1a6-95bf0ae2ba29",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "30 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart + Globoplay",
            speed: 700,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/da622255-40dc-4274-9fbd-bd1f1af41c58",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Smart + Wifi",
            speed: 700,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/97b0c968-65ee-476c-a39e-76edcc91c053",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
          {
            title: "Smart + Max",
            speed: 700,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/d37b1652-ac17-49aa-b695-9ef4ffcca2bc",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Plus",
        plans: [
          {
            title: "Plus",
            speed: 800,
            speedType: "MB",
            amount: 114.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/abad795d-3c2c-4917-be2e-4b6810857ff9",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Plus + Móvel",
            speed: 800,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/4bb95314-04e5-4cd4-9374-641b5e2911e0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "40 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Plus + Globoplay",
            speed: 800,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ce154fc4-3faf-4527-beb7-f4e58e2788d8",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Plus + Wifi",
            speed: 800,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/25523077-f127-46ba-8262-17ce19675825",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
          {
            title: "Plus + Max",
            speed: 800,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/5be25aea-844b-4be2-9177-f2231a6ec7cb",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Essencial Pro",
        plans: [
          {
            title: "Pro",
            speed: 900,
            speedType: "MB",
            amount: 114.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b6c859cf-31a8-4ec4-85bd-c680fb68e4dc",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "MAX",
                icon: "max.png",
              },
            ],
          },
          {
            title: "Pro + Móvel",
            speed: 900,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/634a1bc2-172d-4523-8ac8-f3c293a99874",
            benefits: [
              {
                title: "Wi-Fi 6 - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "55 GB para pacote mobile",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Pro + Globoplay",
            speed: 900,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b2ca2602-cf5f-4194-b075-9c21cc56878d",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "Globoplay | c/ anúncio",
                icon: "globoplay.png",
              },
            ],
          },
          {
            title: "Pro + Wifi",
            speed: 900,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/0996bbd2-4aa0-4d6c-a61c-54f9a6447b2e",
            benefits: [
              {
                title: "Wi-Fi 6  - AX3",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Graviola",
                icon: "graviola_icon.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "ITOP FI",
                icon: "router.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Pessoa jurídica",
    plans: [
      {
        title: "Enterprise",
        plans: [
          {
            title: "Enterprise Slim",
            speed: 600,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/71c05a8e-f021-439d-b664-1b47bba19ca0",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Premium",
            speed: 700,
            speedType: "MB",
            amount: 179.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ddd2475b-4acb-471b-9198-867bd0b18504",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 199.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/ebc03ce1-6d59-4396-aa3f-de7e52485dec",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
      {
        title: "Corp",
        plans: [
          {
            title: "Corp Conect",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/9fab685a-a780-4e92-bc37-39e479139a7d",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Plus",
            speed: 700,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/142e740e-b672-4067-8361-7b30fa606edd",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 184.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/6c9f9577-795c-49cb-9d2f-c0aeb2564303",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 50 GB",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
      {
        title: "Business",
        plans: [
          {
            title: "Business Line",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/55f9f4f5-cde8-4add-9546-d8583152230f",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Master",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/150830ff-cbf6-4354-b3f9-a210505ac047",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 189.9,
            recurrence: "mensal",
            buyLink:
              "https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/7ef22983-7026-4925-805d-d27f4da6796a",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              // {
              //   title: "UBOOK",
              //   icon: "ubook.png",
              // },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Plans;
